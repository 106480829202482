<script>
/***
 Name: example1
 Code: sa0ChunLuyu
 Time: 2021/10/15 23:58
 Remark: 示例1
 */
export default {
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <div>
    example1
  </div>
</template>
<style scoped>

</style>